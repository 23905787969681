import DevDotTo from 'simple-icons/icons/devdotto'
import Medium from 'simple-icons/icons/medium'
import Reddit from 'simple-icons/icons/reddit'
import Twitter from 'simple-icons/icons/twitter'
import Hashnode from 'simple-icons/icons/hashnode'
import { getCachedFetcher } from '../../utils/fetch'

const VERSION = process.env.GATSBY_BUILD_TIMESTAMP

export const fetchCounts = getCachedFetcher({
  name: 'webmention.io:count',
  version: VERSION,
  maxCacheAge: 15 * 60 * 1000, // FIFTEEN_MINUTES,
  baseUrl: 'https://webmention.io/api/count.json?',
})

export const fetchMentions = getCachedFetcher({
  name: 'webmention.io:mentions',
  version: VERSION,
  maxCacheAge: 30 * 60 * 1000, // THIRTY_MINUTES,
  baseUrl: 'https://webmention.io/api/mentions.jf2?',
})

export const getInitialCounts = (webMentions) => {
  const countByType = webMentions.edges.reduce(
    (byType, { node }) => {
      if (node.wmProperty === 'like-of') {
        byType.like += 1
      }
      if (node.wmProperty === 'mention-of') {
        byType.mention += 1
      }
      if (node.wmProperty === 'in-reply-to') {
        byType.reply += 1
      }
      if (node.wmProperty === 'repost-of') {
        byType.repost += 1
      }
      return byType
    },
    { like: 0, mention: 0, reply: 0, repost: 0 }
  )

  return {
    count: Object.values(countByType).reduce((count, n) => count + n, 0),
    type: countByType,
  }
}

const mentionKeyMapper = {
  'bookmark-of': 'bookmarkOf',
  'in-reply-to': 'inReplyTo',
  'like-of': 'likeOf',
  'mention-of': 'mentionOf',
  'repost-of': 'repostOf',
  'wm-id': 'wmId',
  'wm-private': 'wmPrivate',
  'wm-property': 'wmProperty',
  'wm-received': 'wmReceived',
  'wm-source': 'wmSource',
  'wm-target': 'wmTarget',
  'swarm-coins': 'swarmCoins',
}

export const formatMentionsResponseShape = (response) => {
  return {
    ...response,
    children: response.children.map((mention) => {
      for (const key of Object.keys(mentionKeyMapper)) {
        mention[mentionKeyMapper[key]] = mention[key]
        delete mention[key]
      }
      return mention
    }),
  }
}

const siloMeta = {
  'dev.to': {
    name: 'Dev.to',
    icon: DevDotTo,
    iconStyle: {
      display: 'block',
      width: 'auto',
      height: '1.7em',
    },
  },
  'hashnode.dev': {
    name: 'Hashnode',
    icon: Hashnode,
    iconStyle: {
      display: 'block',
      width: 'auto',
    },
  },
  'medium.com': {
    name: 'Medium',
    icon: Medium,
    iconStyle: {
      display: 'block',
      width: 'auto',
    },
  },
  'reddit.com': {
    name: 'Reddit',
    icon: Reddit,
    iconStyle: {
      display: 'block',
      width: 'auto',
    },
  },
  'twitter.com': {
    name: 'Twitter',
    icon: Twitter,
    iconStyle: {
      display: 'block',
      width: 'auto',
    },
  },
}

export const getSilo = (syndicationUrl) => {
  const domain = syndicationUrl.split('/')[2]

  if (!domain) {
    return null
  }

  const id = domain.split('.').slice(-2).join('.')

  const silo = siloMeta[id]

  if (!silo) {
    return {
      id,
      domain,
      name: id,
      icon: null,
      iconStyle: {
        display: 'block',
        width: 'auto',
      },
    }
  }

  return {
    id,
    domain,
    ...silo,
  }
}

export const wordByProperty = {
  'like-of': {
    singular: 'Like',
    plural: 'Likes',
  },
  'mention-of': {
    singular: 'Mention',
    plural: 'Mentions',
  },
  'in-reply-to': {
    singular: 'Reply',
    plural: 'Replies',
  },
  'repost-of': {
    singular: 'Repost',
    plural: 'Reposts',
  },
}
