/** @jsx jsx */
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Box, Divider, jsx, Themed } from 'theme-ui'
import Layout from '../Layout'
import InertLink from '../Link/InertLink'
import SEO from '../SEO'
import { WebMentions } from '../WebMention/WebMentions'
import PostAuthor from './PostAuthor'
import PostDate from './PostDate'
import PostTitle from './PostTitle'

function BlogPost({
  data: {
    blogPost,
    site,
    webMentions,
    lastWebMention: {
      edges: [lastWebMention],
    },
  },
  pageContext,
}) {
  const { id, slug, date, title, body, excerpt, keywords, syndication } =
    blogPost
  const { siteMetadata } = site
  const { permalink } = pageContext

  return (
    <Themed.root key={id}>
      <SEO
        url={permalink}
        title={title}
        description={excerpt}
        keywords={keywords}
      />

      <Layout siteMetadata={site.siteMetadata}>
        <Box as="article" className="h-entry">
          <Box as="header">
            <InertLink to={slug} className="u-url">
              <PostTitle title={title} />
            </InertLink>
            <PostDate date={date} />
            <PostAuthor siteMetadata={siteMetadata} />
          </Box>

          <Box as="section" className="e-content">
            <MDXRenderer>{body}</MDXRenderer>
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        <WebMentions
          permalink={permalink}
          webMentions={webMentions}
          lastWebMention={lastWebMention}
          syndication={syndication}
        />
      </Layout>
    </Themed.root>
  )
}

export default BlogPost
