/** @jsx jsx */
import { DateTime } from 'luxon'
import { useMemo, useState, Fragment } from 'react'
import { Avatar, Box, Flex, jsx, Link, Text, Button } from 'theme-ui'
import { usePostDate } from '../../hooks/usePostDate'
import SimpleIcon from '../SimpleIcon'
import { useCounts, useMentions } from './hooks'
import { getSilo, wordByProperty } from './utils'

function CountButton({ type, setType, wmProperty, count }) {
  const color = useMemo(() => {
    return type === wmProperty ? 'primary' : 'inherit'
  }, [type, wmProperty])

  if (!count) {
    return null
  }

  return (
    <Button
      variant="ghost"
      sx={{
        fontWeight: 'bold',
        mx: '0.5em',
        my: '0.25em',
        whiteSpace: 'nowrap',
        color,
      }}
      onClick={() =>
        setType((type) => (type === wmProperty ? null : wmProperty))
      }
    >
      <span>{`${count} ${
        wordByProperty[wmProperty][count === 1 ? 'singular' : 'plural']
      }`}</span>
    </Button>
  )
}

function SyndicationItem({ url }) {
  const silo = useMemo(() => getSilo(url), [url])

  if (!silo.icon) {
    return null
  }

  return (
    <Link
      key={silo.id}
      href={url}
      className="u-syndication"
      target="_blank"
      rel="syndication noopener noreferrer"
      aria-label={`View on ${silo.id}`}
      sx={{
        mx: '0.5em',
        my: '0.25em',
        display: 'inline-block',
        color: 'currentColor',
        opacity: 0.9,
        transition: 'color 0.15s, transform 0.1s',
        ':hover': {
          opacity: 1,
          color: `#${silo.icon.hex}`,
        },
      }}
    >
      <SimpleIcon icon={silo.icon} style={silo.iconStyle} />
    </Link>
  )
}

function WebMentionAvatar({ author, className, hideName }) {
  return (
    <Link
      className={className}
      href={author.url}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ textDecoration: 'none' }}
    >
      <Avatar
        alt={author.name}
        src={author.photo}
        sx={{
          display: 'inline-block',
          width: '2em',
          height: '2em',
          mr: '0.25em',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />

      {!hideName && (
        <Text as="span" sx={{ display: 'inline-block' }}>
          {author.name}
        </Text>
      )}
    </Link>
  )
}

function WebMentionUrl({ children, url }) {
  const silo = getSilo(url)

  return (
    <Link
      className="u-url"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ textDecoration: 'none' }}
    >
      {silo.name}

      {children}
    </Link>
  )
}

const itemClassNameByProperty = {
  'like-of': 'p-like',
  'mention-of': '',
  'in-reply-to': 'u-comment',
  'repost-of': 'p-repost',
}

function LikeItem({ author, url, wmProperty }) {
  return (
    <Box
      as="article"
      className={`${itemClassNameByProperty[wmProperty]} h-cite`}
      sx={{ ml: 2, mb: 2, position: 'relative', minHeight: '2em' }}
    >
      <Text sx={{ display: 'inline-block', ml: '2.5em', py: '0.125em' }}>
        <WebMentionAvatar author={author} className="p-author h-card" />
        {' liked this on '}
        <WebMentionUrl url={url} />
      </Text>
    </Box>
  )
}

function MentionItem({ author, url, wmProperty }) {
  const silo = getSilo(url)

  return (
    <Box
      as="article"
      className={`${itemClassNameByProperty[wmProperty]} h-cite`}
      sx={{ ml: 2, mb: 2, position: 'relative', minHeight: '2em' }}
    >
      <Text sx={{ display: 'inline-block', ml: '2.5em', py: '0.125em' }}>
        {author.name ? (
          <WebMentionAvatar author={author} hideName />
        ) : (
          <Box
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              display: 'inline-block',
              width: '2em',
              height: '2em',
              mr: '0.25em',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <svg height="2em" width="2em">
              <rect
                fill={silo.icon?.hex ?? '#663399'}
                x="0"
                y="0"
                height="2em"
                width="2em"
              ></rect>
              <text
                fill="#ffffff"
                fontSize="1em"
                textAnchor="middle"
                x="1em"
                y="1.35em"
              >
                {String(silo.id).slice(0, 1).toUpperCase()}
              </text>
            </svg>
          </Box>
        )}
        Received mention on <WebMentionUrl url={url} />
        {author.name && (
          <Fragment>
            {' by '}
            <Link
              className="p-author h-card"
              href={author.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              <Text as="span" sx={{ display: 'inline-block' }}>
                {author.name}
              </Text>
            </Link>
          </Fragment>
        )}
      </Text>
    </Box>
  )
}

function ReplyItem({ author, content, published, url, wmProperty }) {
  const date = usePostDate(published, DateTime.DATE_MED)

  return (
    <Box
      as="article"
      className={`${itemClassNameByProperty[wmProperty]} h-cite`}
      sx={{ ml: 2, mb: 3, mt: 2, position: 'relative', minHeight: '2em' }}
    >
      <Box sx={{ ml: '2.5em', py: '0.125em' }}>
        <Box
          className="p-content p-name"
          sx={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {content.text}
        </Box>
        <Box sx={{ fontSize: 1 }}>
          {' — '}
          <WebMentionAvatar author={author} className="u-author h-card" />
          {' on '}
          <WebMentionUrl url={url}>
            <Text
              as="time"
              className="dt-published"
              dateTime={date.iso}
              sx={{
                fontSize: 0,
                position: 'absolute',
                top: '-1.25em',
                left: '3.33em',
              }}
            >
              {date.display}
            </Text>
          </WebMentionUrl>
        </Box>
      </Box>
    </Box>
  )
}

function RepostItem({ author, url, wmProperty }) {
  return (
    <Box
      as="article"
      className={`${itemClassNameByProperty[wmProperty]} h-cite`}
      sx={{ ml: 2, mb: 2, position: 'relative', minHeight: '2em' }}
    >
      <Text sx={{ display: 'inline-block', ml: '2.5em', py: '0.125em' }}>
        <WebMentionAvatar author={author} className="p-author h-card" />
        {' reposted this on '}
        <WebMentionUrl url={url} />
      </Text>
    </Box>
  )
}

const itemComponentByProperty = {
  'like-of': LikeItem,
  'mention-of': MentionItem,
  'in-reply-to': ReplyItem,
  'repost-of': RepostItem,
}

function WebMentionItem({ data }) {
  const Component = itemComponentByProperty[data.wmProperty]

  if (!Component) {
    return null
  }

  return <Component {...data} />
}

export function WebMentions({
  permalink,
  webMentions,
  lastWebMention,
  syndication,
}) {
  const [type, setType] = useState(null)

  const counts = useCounts({ permalink, webMentions })

  const mentions = useMentions({ permalink, webMentions, lastWebMention })

  const { mentionsOfType, mentionWithAuthors } = useMemo(() => {
    return {
      mentionsOfType: mentions.filter((mention) => mention.wmProperty === type),
      mentionWithAuthors: mentions.filter(
        (mention) => mention.author && mention.author.photo
      ),
    }
  }, [mentions, type])

  return (
    <Box>
      <Flex
        sx={{
          direction: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          mb: 3,
        }}
      >
        <Flex sx={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <CountButton
            type={type}
            setType={setType}
            wmProperty="like-of"
            count={counts.type.like}
          />
          <CountButton
            type={type}
            setType={setType}
            wmProperty="repost-of"
            count={counts.type.repost}
          />
          <CountButton
            type={type}
            setType={setType}
            wmProperty="in-reply-to"
            count={counts.type.reply}
          />
          <CountButton
            type={type}
            setType={setType}
            wmProperty="mention-of"
            count={counts.type.mention}
          />
        </Flex>

        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {syndication.map((url) => (
            <SyndicationItem key={url} url={url} />
          ))}
        </Flex>
      </Flex>

      {type ? (
        <Flex sx={{ flexDirection: 'column' }}>
          {mentionsOfType.map((webMention) => (
            <WebMentionItem key={webMention.wmId} data={webMention} />
          ))}
        </Flex>
      ) : (
        <Flex sx={{ flexDirection: 'row', flexWrap: 'wrap', mb: 4 }}>
          {mentionWithAuthors.map(({ wmId, wmProperty, author }) => (
            <Box
              key={wmId}
              as="article"
              className={`${itemClassNameByProperty[wmProperty]} h-cite`}
              sx={{
                position: 'relative',
                width: 48,
                height: 48,
                mr: '-16px',
                transition: 'transform 0.2s',
                ':hover': {
                  transform: 'scale(1.2)',
                  zIndex: 99,
                },
              }}
            >
              <Link
                className="p-author h-card"
                href={author.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: 'block' }}
              >
                <Avatar
                  alt={author.name}
                  src={author.photo}
                  sx={{ display: 'block', border: '3px solid #fff' }}
                />
              </Link>
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  )
}
