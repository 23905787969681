import { useEffect, useState } from 'react'
import {
  fetchCounts,
  fetchMentions,
  formatMentionsResponseShape,
  getInitialCounts,
} from './utils'

export const useCounts = ({ permalink, webMentions }) => {
  const [counts, setCounts] = useState(() => getInitialCounts(webMentions))

  useEffect(() => {
    fetchCounts({ id: permalink, url: `target=${permalink}` })
      .then((responseCounts) => {
        setCounts((previousCounts) => ({
          ...previousCounts,
          ...responseCounts,
          type: {
            ...previousCounts.type,
            ...responseCounts.type,
          },
        }))
      })
      .catch((err) => {
        console.error(err)
      })
  }, [permalink])

  return counts
}

export const useMentions = ({ permalink, webMentions, lastWebMention }) => {
  const [mentions, setMentions] = useState(() => {
    return webMentions.edges.map(({ node }) => node)
  })

  useEffect(() => {
    const sinceId = lastWebMention ? lastWebMention.node.wmId : 0

    let url = `target=${permalink}`

    if (sinceId) {
      url += `&since_id=${sinceId}`
    }

    fetchMentions({
      id: `${permalink}:since:${sinceId}`,
      groupId: `${permalink}`,
      url,
    })
      .then(formatMentionsResponseShape)
      .then(({ children: newMentions }) => {
        setMentions((oldMentions) => newMentions.concat(oldMentions))
      })
      .catch((err) => {
        console.error(err)
      })
  }, [permalink, lastWebMention])

  return mentions
}
