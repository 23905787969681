import { graphql } from 'gatsby'
import BlogPost from '../components/BlogPost'

export default BlogPost

export const query = graphql`
  query BlogPostQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $maxWidth: Int
    $permalink: String!
  ) {
    site {
      siteMetadata {
        title
        author
      }
    }
    blogPost(id: { eq: $id }) {
      id
      excerpt
      body
      slug
      title
      tags
      keywords
      date
      image {
        childImageSharp {
          fluid(maxWidth: $maxWidth) {
            ...GatsbyImageSharpFluid
            src
          }
        }
      }
      imageAlt
      socialImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            src
          }
        }
      }
      syndication
    }
    previous: blogPost(id: { eq: $previousId }) {
      id
      excerpt
      slug
      title
      date
    }
    next: blogPost(id: { eq: $nextId }) {
      id
      excerpt
      slug
      title
      date
    }
    lastWebMention: allWebMentionEntry(
      filter: { wmTarget: { eq: $permalink } }
      sort: { fields: wmId, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
          wmId
        }
      }
    }
    webMentions: allWebMentionEntry(filter: { wmTarget: { eq: $permalink } }) {
      edges {
        node {
          id
          type
          url
          wmId
          wmProperty
          wmSource
          wmTarget
          author {
            name
            photo
            type
            url
          }
          content {
            text
          }
          published
        }
      }
    }
  }
`
