const clearCachedData = async (cacheKeyPattern) => {
  try {
    const keys = Object.keys(window.localStorage)
    for (const key of keys) {
      if (cacheKeyPattern.test(key)) {
        window.localStorage.removeItem(key)
      }
    }
  } catch (err) {
    console.error(err)
  }
}

const getCachedData = async ({
  cacheKey,
  cacheKeyPattern,
  version,
  maxCacheAge,
}) => {
  try {
    const now = Date.now()

    const jsonBlob = window.localStorage.getItem(cacheKey)

    const cache = jsonBlob ? JSON.parse(jsonBlob) : null

    if (!cache) {
      return null
    }

    if (cache.v !== version || typeof cache.ts !== 'number') {
      await clearCachedData(cacheKeyPattern)
      return null
    }

    if (now - cache.ts < maxCacheAge) {
      return cache.data
    }
  } catch (err) {
    console.error(err)

    return null
  }
}

const setCachedData = async ({ cacheKey, cacheKeyPattern, version, data }) => {
  try {
    await clearCachedData(cacheKeyPattern)
    const cache = { ts: Date.now(), v: version, data }
    window.localStorage.setItem(cacheKey, JSON.stringify(cache))
  } catch (err) {
    console.error(err)
  }
}

export const getCachedFetcher = ({
  name,
  baseUrl = '',
  maxCacheAge,
  version,
}) => {
  return async ({ id, groupId = id, url }) => {
    const cacheKey = `${name}:${id}`
    const cacheKeyPattern = new RegExp(`^${name}:${groupId}`)

    const cachedData = await getCachedData({
      cacheKey,
      cacheKeyPattern,
      version,
      maxCacheAge,
    })

    if (cachedData) {
      return cachedData
    }

    const response = await fetch(`${baseUrl}${url}`)

    const data = await response.json()

    await setCachedData({
      cacheKey,
      cacheKeyPattern,
      version,
      data,
    })

    return data
  }
}
